#products{
    --page-section-bg-color: #eaeaea;



    .page-section-heading{
        margin-bottom: calc(var(--page-section-padding) * 0.25);
    }

    .product-filters{
        position: sticky;
        top: 56px;
        z-index: 8;
        transition: all ease-in-out .2s;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 0.6em 0;

        .filter{
            margin: 0.6em;
            padding: 0.6em 1.8em;
            line-height: 1;
            background-color: rgba(0,0,0,0.1);
            border-radius: 99px;

            font-size: 1.25em;
            font-family: var(--font-b);
            font-weight: 500;

            cursor: pointer;
            transition: all ease-in-out .2s;

            &:hover{
                background-color: rgba(0,0,0,0.17);
            }

            &.is-selected{
                font-weight: 600;
                background-color: rgba(0,0,0,0.25);
            }

            @media (max-width: 600px){
                margin: 0.4em;
            }

        }


        margin-left: calc(-1 * var(--page-section-padding)) !important;
        margin-right: calc(-1 * var(--page-section-padding)) !important;
        padding-left: calc(var(--page-section-padding)) !important;
        padding-right: calc(var(--page-section-padding)) !important;
        
        @media (min-width: 1420px){
            &.is-stuck{
                background-color: rgba(244, 244, 244, 0.9);
                box-shadow: 0 0 25px rgba(0,0,0,0.2);
                font-size: 0.8em;

                .filter{
                    margin: 0.4em;
                }
            }
        }

        @media (max-width: 750px){
            font-size: 0.9em;
            margin: 0 -0.6em;
        }

        @media (max-width: 1420px){
            position: static;
        }
    }

    .change-filter-sticky{
        position: fixed;
        top: 56px;
        left: 0;
        right: 0;
        z-index: 6;

        padding: 0.5em;
        background-color: rgba(244, 244, 244, 0.9);
        box-shadow: 0 0 25px rgba(0,0,0,0.2);

        font-size: 1.25em;
        text-align: center;
        font-family: var(--font-b);
        color: #555;

        b{
            font-weight: 600;
            color: #000;
        }
    }


    .product-list{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 2.5em;

        .product{
            width: 33.33%;
            padding: 2.5em;
            cursor: pointer;

            .name{
                font-size: 1.5em;
                margin-top: 1.2em;
                text-align: center;

                @media (max-width: 480px) {
                    font-size: 1.6em;   
                }
            }

            &:hover{
                background-color: rgba(0,0,0,0.05);
            }

            @media (max-width: 970px){
                width: 50%;
            }

            @media (max-width: 480px){ // TODO : cheggit
                padding: 1em;
            }
        }

        @media (max-width: 480px){ // TODO : cheggit
            margin-top: 1em;
        }
    }

    @media (max-width: 480px) {
        font-size: 0.8rem;   
    }
}

