.overflow-image{
    width: calc(100% * var(--relative-scale,1));
    margin: 0 auto;
    padding-top: calc(100% * var(--height)/var(--width) * var(--relative-scale,1));
    position: relative;

    img{
        position: absolute;
        top: 0;

        --scale: calc(1/var(--width));
        width: calc(100% * var(--scale));
        margin-left: calc(-100% * var(--x) * var(--scale));

        pointer-events: none;
    }
}