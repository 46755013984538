*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.popup {
    position: fixed;
    width: auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #b2cae2;;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 9999; /* Adjust as needed */
}
  
  .popup-inner {
    text-align: center;
  }
  .popup-inner img{
    width: 400px;
    height: auto;
  }
  
  .popup .close-btn {
    background-color: #b8c2cc;
    padding: 10px;
    position: absolute;
    top: 10px; /* Adjust top position as needed */
    right: 10px; /* Adjust right position as needed */
    cursor: pointer;
}
  
:focus{
    outline: none;
}

input, button, textarea{
    font: inherit;
}

a{
    color: inherit;
}

body{
    -webkit-tap-highlight-color: transparent;
    overflow-x: hidden;
}




:root{
    --bird-blue: #8DB7E1;
    --bird-pink: #EA76A5;
}

:root{
    --font-a: "Muli";
    --font-b: "Raleway";
}



body{
    font-family: var(--font-a);
}

html{
    font-size: 100%;

    @media (max-width: 1200px){
        font-size: 90%;
    }

    @media (max-width: 780px){
        font-size: 80%;
    }
}

::selection{
    background-color: rgba(0,0,0,0.15);
}


.skeleton-loading{
    --bg-color: #d9d9d9;
    --fg-color: #c5c5c5;
}

.skeleton-loading .skeleton{
	position: relative;
}
.skeleton-loading .skeleton-inline-block{
	display: inline-block;
}
.skeleton-loading .skeleton > *{
	opacity: 0;
}
.skeleton-loading .skeleton::after{
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
    background-color: var(--bg-color);
	background-image: linear-gradient(90deg, var(--bg-color) 0%, var(--fg-color) 40%, var(--fg-color) 60%, var(--bg-color) 100%);
	background-repeat: no-repeat;
	background-size: 50% 100%;
	animation: skeleton 1s infinite linear;
}
@keyframes skeleton{
	0%{
		background-position: -50%;
	}
	100%{
        background-position: 250%;
	}
}
