@import "~mathsass/dist/_math";

body{
    overflow-x: hidden;
}

$section-slant-angle: 5deg;
$section-slant-angle-tan: tan($section-slant-angle);
$section-slant-angle-sec: sec($section-slant-angle);
$section-slant-angle-sin: sin($section-slant-angle);

:root{
    --page-section-slant-angle: #{$section-slant-angle};
    --page-section-slant-angle-tan: #{$section-slant-angle-tan};
    --page-section-slant-angle-sec: #{$section-slant-angle-sec};
    --page-section-slant-angle-sin: #{$section-slant-angle-sin};
    
    --page-section-padding: 5vw;

    --page-section-heading-font-size: 3.25rem;
    @media (max-width: 550px){
        --page-section-heading-font-size: 2.75em;
    }
}

.page-section{

    position: relative;
    padding: 0 var(--page-section-padding, 0px) calc(
          (var(--page-section-slant-angle-tan) * 100vw)
        - (
            var(--page-section-slant-angle-tan) * 100vw
            - var(--page-section-slant-angle-tan) * (
                var(--page-section-heading-end, 50vw) + var(--page-section-padding, 0px)
            )
            - var(--page-section-heading-margin-top, 0px)
        )
        + (var(--page-section-padding-bottom, var(--page-section-padding, 0px)))
    );
    z-index: 2;
    background-color: var(--page-section-bg-color, #FFFFFF);

    --page-section-wrapper-top: calc(
        -100vw * var(--page-section-slant-angle-tan)
        - var(--page-section-start-offset-y)
        + var(--page-section-start-end-offset-x) * var(--page-section-slant-angle-tan)
        + var(--page-section-padding-top, var(--page-section-padding, 0px))
    );
    padding-bottom: calc(
        100vw * var(--page-section-slant-angle-tan)
        + var(--page-section-wrapper-top)
        + var(--page-section-padding-bottom, var(--page-section-padding, 0px))
    );

    .page-section-wrapper{
        position: relative;
        top: var(--page-section-wrapper-top);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .page-section-heading{
            font-family: var(--font-b);
            text-transform: uppercase;
            font-size: var(--page-section-heading-font-size);
        }
    }

    &::before{
        content: "";
        position: absolute;
        width: calc(100vw * var(--page-section-slant-angle-sec));
        height: calc(100vw * var(--page-section-slant-angle-tan) * var(--page-section-slant-angle-sec));
        left: 0;
        top: calc(-1 * 100vw * var(--page-section-slant-angle-tan));
        transform: rotate(var(--page-section-slant-angle));
        transform-origin: top left;
        background-color: var(--page-section-bg-color, #FFFFFF);
        z-index: -1;
    }
}
