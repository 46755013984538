#main-header{
    position: fixed;
    top: -0;
    left: 0;
    right: 0;

    height: 7.5em;
    z-index: 9;
    transition: all ease-in-out .2s;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;

    --main-header-height: 20px;
    --main-header-padding-x: 20px;
    --main-header-padding-y: 20px;

    &.is-scrolled{
        background-color: #000;
        height: 56px;
        padding: 12px var(--page-section-padding);
        --main-header-padding-x: var(--page-section-padding);

        @media (max-width: 700px) {
            padding: 12px 20px;
            --main-header-padding-x: 20px;
        }
    }



    
    

    /* ------------------------------------------------- */
    /* Logo */

    .logo{
        height: 100%;
        transition: all ease-in-out .1s;
        
        .logo-full{
            height: 100%;
            transition: all ease-in-out .1s;
        }

        @media (max-width: 490px){
            height: 80%;
            position: relative;
            top: 10%;
        }
    }

    &.is-back-header{
        .logo{
            transform: translateX(calc(
                -1 * var(--main-header-padding-x)
                + 50vw
                - 50%
            ));
        }
    }

    &.is-scrolled{
        .logo{
            .logo-full{
                height: 200%;
                position: relative;
                top: -100%;

                .bird-text, .meditech-text, .left-box, .right-box{
                    opacity: 0;
                }
            }

            @media (max-width: 700px) {
                transform-origin: left;
            }
        }
    }

    &.is-back-header.is-scrolled{
        .logo{
            transform: translateX(calc((
                -1 * var(--main-header-padding-x)
                + 50vw
                - 50%
            ) / 1))
        }
    }







    /* ------------------------------------------------- */
    /* Nav */

    nav{
        display: flex;
        transition: all ease-in-out .2s;

        font-family: var(--font-b);

        a{
            text-decoration: none;
            text-transform: uppercase;
            font-size: 1.2em;
            font-weight: 600;
            margin-left: 1em;

            opacity: 0.7;
            transition: all ease-in-out .2s;

            &:hover{
                opacity: 0.9;
            }

            &.active{
                opacity: 1;
            }
        }

        @media (max-width: 490px){
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background-color: rgba(0,0,0,0.95);
            transition: all ease-in-out .2s, clip-path .1s linear .1s;

            flex-direction: column;
            align-items: flex-end;
            padding-top: 66px;
            padding-right: var(--main-header-padding-x);
            
            color: #fff;

            clip-path: circle(
                0px at
                calc(100vw - 20px - 16px)
                calc(20px + 16px)
            );

            a{
                font-size: 2.5em;
                line-height: 1;
                margin-bottom: 10px;
            }
        }
    }
    
    .menu-checkbox:checked ~ nav{
        clip-path: circle(
            120vmax at
            calc(100vw - 20px - 16px)
            calc(20px + 16px)
        );
    }

    &.is-back-header{
        nav{
            opacity: 0;
        }
    }

    &.is-scrolled{
        nav{
            color: #FFF;
            align-self: center;
            margin-right: 0.6em;

            @media (max-width: 490px){
                margin-right: 0;
                padding-top: 56px;
            }
        }
    }







    /* ------------------------------------------------- */
    /* Menu  */

    .menu-checkbox{
        display: none;
    }

    .menu-btn{
        width: 32px; // headerheight - headerPaddingY*2 = 56 - 12*2 = 32
        height: 32px;
        cursor: pointer;
        z-index: 8;

        display: flex;
        display: none;
        justify-content: center;
        align-items: center;
        position: relative;

        color: #000;

        &::before,
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 26px;
            height: 2px;

            background-color: currentColor;
            transition: all ease-in-out .2s;

            pointer-events: none;
        }

        &::before{
            top: 10px;
        }

        &::after{
            bottom: 10px;
        }

        @media (max-width: 490px){
            display: flex;
        }
    }

    .menu-checkbox:checked ~ .menu-btn{
        color: #fff;

        &::before{
            transform: translateY(5px) rotate(-45deg);
        }
        &::after{
            transform: translateY(-5px) rotate(45deg);
        }
    }

    &.is-back-header{
        .menu-btn{
            opacity: 0;
        }
    }

    &.is-scrolled{
        .menu-btn{
            color: #fff;
        }
    }







    /* ------------------------------------------------- */
    /* Back btn  */

    .back-btn{
        position: fixed;
        left: var(--main-header-padding-x);
        left: -21px;
        align-self: center;

        height: 21px;
        cursor: pointer;
        transition: all ease-in-out .1s;
        
        color: #fff;

        path{
            fill: currentColor;
        }

        @media (max-width: 490px){
            height: 18px;

        }
    }

    &.is-back-header{
        .back-btn{
            left: var(--main-header-padding-x);
        }
    }

}