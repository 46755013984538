#contact {
    --page-section-bg-color: #232323;
    --page-section-padding-bottom: 0px;
    color: #FFFFFF;

    .page-section-heading {
        margin-bottom: 0.75em;
    }

    .page-section-content {
        font-size: 1.5em;
        line-height: 1.2;

        p {
            margin-bottom: 1em;
        }

        .part {
            margin-bottom: 0.25em;
        }
    }

    .social-icons a {
        margin: 0 15px;
        /* Adds space between the icons */
        color: #fff;
        /* Icon color */
        font-size: 30px;
        /* Icon size */
        transition: color 0.3s;
    }

    /* Add hover effect */
    .social-icons a:hover {
        color: #0077b5;
        /* LinkedIn blue or you can customize colors */
    }

    /* Custom color for each icon if desired */
    .social-icons a .fa-facebook:hover {
        color: #3b5998;
        /* Facebook color */
    }

    .social-icons a .fa-linkedin:hover {
        color: #0077b5;
        /* LinkedIn color */
    }

    .social-icons a .fa-instagram:hover {
        color: #e4405f;
        /* Instagram color */
    }

    /* Custom hover color for YouTube */
    .social-icons a .fa-youtube:hover {
        color: #ff0000;
        /* YouTube red */
    }


    @media (max-width: 480px) {
        font-size: 0.8rem;
    }
}