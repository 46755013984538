#intro{
    height: calc(100vh + var(--page-section-slant-angle-tan) * 100vw);
    position: relative;
    z-index: 1;

    background-image: url("intro-bg.jpg");
    background-position: center;
    background-size: cover;

    .intro-content{
        height: var(--icb-100vh, 100vh);
        padding: 0 var(--page-section-padding);
        position: relative;
        z-index: 3;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        --tagline-big-font-size: 5em;
        @media (max-width: 1300px){
            --tagline-big-font-size: 4.5em;
        }

        h1{
            font-family: var(--font-b);
            font-weight: 700;
            font-size: var(--tagline-big-font-size);
            text-transform: uppercase;
            line-height: 1;
            margin: 10px 0 8px;

            br{
                @media (max-width: 550px) {
                    display: none;
                }
            }

            @media (max-width: 500px){
                margin: 5px 0 3px;
            }
        }

        h2{
            font-family: var(--font-a);
            font-weight: 400;
            font-size: calc(var(--tagline-big-font-size) * 0.5);
            line-height: 1;
            
            @media (max-width: 500px){
                font-size: calc(var(--tagline-big-font-size) * 0.7);
            }
        }
    }

    .overlay-normal,
    .overlay-color{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        background-image: linear-gradient(var(--bird-blue), var(--bird-pink));
    }
    .overlay-normal{
        z-index: 1;
        opacity: 0.6;
    }
    .overlay-color{
        z-index: 2;
        mix-blend-mode: color;
    }

    @media (max-width: 550px){
        font-size: 0.9rem;
    }
    
    @media (max-width: 500px){
        font-size: 0.7rem;
    }

    @media (max-width: 395px){
        font-size: 0.6rem;
    }
}