#certification{
    --page-section-bg-color: #eaeaea;


    .page-section-heading{
        margin-bottom: 0.75em;
    }

    .page-section-content{
        font-size: 1.5em;
        line-height: 1.2;

        p{
            margin-bottom: 1em;
        }
    }
    .image {
        width: 500px; // Set width
        height: auto; // Maintain aspect ratio
        margin-top: 20px; // Adjust top margin
      }

    @media (max-width: 480px) {
        font-size: 0.8rem;
    }
}