#product-categories{
    --page-section-bg-color: #FFFFFF;

    --page-section-padding: 5vw;
    --page-section-padding-bottom: 0px;
    @media (max-width: 700px) {
        --page-section-padding-top: calc(5vw + 1.2em + 10px); // 10px for optical padding
        --page-section-padding-bottom: calc(5vw + 0.5em); // TODO : fix magic number
    }

    --top-right-category-index: 3;
    @media (max-width: 1120px){
        --top-right-category-index: 2;
    }/*
    @media (max-width: 425px) {
        --top-right-category-index: 1;
    }*/
    
    position: relative;


    .page-section-content{
        display: flex;
        justify-content: center;
        align-items: baseline;
        flex-wrap: wrap;
        margin: 0 12.5%;
    
        .category{
            --column-count: 4;
            width: calc(100% / var(--column-count));

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 1.5em;

            cursor: pointer;

            @media(max-width: 480px){
                padding: 1.2em;
            }

            .category-icon{
                width: 10em;
                
                @media(max-width: 480px){
                    width: 7em;
                }

                path {
                    fill: currentColor;
                }
            }

            .category-name{
                font-family: var(--font-b);
                font-size: 1.5em;
                font-weight: 600;
                
                @media(max-width: 480px){
                    font-size: 1.3em;
                }
            }

            @media (max-width: 1120px){
                --column-count: 3;
            }
            /*
            @media (max-width: 425px) {
                --column-count: 2;
            }*/
        }

        @media (max-width: 1320px) {
            margin: 0 var(--page-section-padding);
        }

        @media (max-width: 700px) {
            margin: 0;
        }

        @media (max-width: 1120px){
            align-items: center;
        }
    }
    

    @media (max-width: 540px){
        font-size: 0.9rem;
    }

    @media (max-width: 480px){
        font-size: 1rem;
    }

    @media(max-width: 400px){
        font-size: 0.9rem;
    }
}
