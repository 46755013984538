
body.is-showing-product{
    overflow: hidden;
}

.product-page{
    position: fixed;
    top: 56px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #d2d2d2;
    z-index: 99;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 3.5em;
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;


    .product-name{
        font-size: var(--page-section-heading-font-size);
        font-family: var(--font-b);
        text-transform: uppercase;
        text-align: center;
    }

    .product-category{
        font-size: calc(var(--page-section-heading-font-size) * 0.5);
        text-align: center;
        color: rgba(0,0,0,0.7);
    }

    /*
    * Using float grid instead of flex bcoz
    * flex is behaving abnormally / not-as-expected
    * and padding and margins are buggish :/
    */
    .page-content{
        width: 100%;
        margin-top: 2.5em;

        .product-image{
            width: 30%;
            float: left;

            @media (max-width: 850px) {
                float: none;
                width: 100%;
                max-width: 300px;
                margin: 0 auto;
            }
        }

        .product-content{
            width: 70%;
            float: left;
            padding-left: 4em;
            

            @media (max-width: 850px) {
                float: none;
                width: 100%;
                padding: 0;
                padding-top: 2.5em;
            }

            @media (max-width: 480px) {
                font-size: 1.1em;
            }
        }

        &.has-fullpage-content{
            .product-image{ display: none; }
            .product-content{ width: 100%; padding-left: 0; }
        }

        
    }

    opacity: 0;
    transform: translateY(10vh);
    pointer-events: none;
    transition: all ease-in-out .1s;

    &.is-shown{
        opacity: 1;
        transform: translateY(0vh);
        pointer-events: auto;
    }

    .skeleton-loading{
        --bg-color: #c5c5c5;
        --fg-color: #b9b9b9;
    }


    
    @media (max-width: 850px) {
        padding: 2em 2.5em;
    }
}