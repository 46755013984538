#about{
    --page-section-bg-color: #D2D2D2;


    .page-section-heading{
        margin-bottom: 0.75em;
    }

    .page-section-content{
        font-size: 1.5em;
        line-height: 1.2;

        p{
            margin-bottom: 1em;
        }
    }

    @media (max-width: 480px) {
        font-size: 0.8rem;
    }
}